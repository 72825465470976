<template>
  <mu-flex class="mu-transition-row">
    <mu-expand-transition class="full-width">
      <div v-show="showFilter" class="filter-box">
        <div class="content">

          <mu-paper class="mu-transition-box search-box full-width" :z-depth="0">
            <mu-text-field
                v-model="formData.text"
                :placeholder="$t('eventsFilter.searchPlaceholder')"
                full-width
                @keyup.enter="emitEventsFiltered"
            ></mu-text-field>
          </mu-paper>
          <mu-paper class="mu-transition-box full-width" :z-depth="0">
            <div class="more-dates full-width">
              <div class="control">
                <mu-radio :ripple="false" :label-left="true" checked-icon="none" uncheck-icon="none" :value="1"
                          color="red"
                          v-model="dateRange" :label="$t('eventsFilter.anytime')"></mu-radio>
              </div>
              <div class="control">
                <mu-radio :ripple="false" :label-left="true" checked-icon="none" uncheck-icon="none" :value="2"
                          v-model="dateRange" :label="$t('eventsFilter.today')"></mu-radio>
              </div>
              <div class="control">
                <mu-radio :ripple="false" :label-left="true" checked-icon="none" uncheck-icon="none" :value="3"
                          v-model="dateRange" :label="$t('eventsFilter.tomorrow')"></mu-radio>
              </div>
              <div class="control">
                <mu-radio :ripple="false" :label-left="true" checked-icon="none" uncheck-icon="none" :value="4"
                          v-model="dateRange" :label="$t('eventsFilter.thisWeek')"></mu-radio>
              </div>
              <div class="control">
                <mu-radio :ripple="false" :label-left="true" checked-icon="none" uncheck-icon="none" :value="5"
                          v-model="dateRange" :label="$t('eventsFilter.thisWeekend')"></mu-radio>
              </div>

              <span v-if="!showDateRange" class="span-btn" @click="showDateRange = !showDateRange">{{$t('button.moreDates')}}</span>
              <span v-else class="span-btn" @click="showDateRange = !showDateRange">{{$t('button.lessDates')}}</span>

            </div>

            <div v-show="showDateRange" class="more-dates full-width">
              <div class="date-start">
<!--                <mu-date-input-->
<!--                    :label="$t('eventsFilter.eventStartFrom')"-->
<!--                    v-model="start_from"-->
<!--                    type="dateTime"-->
<!--                    :error-text="$t(errors.start_from)"-->
<!--                    label-float-->
<!--                    clock-type="24hr"-->
<!--                    view-type="list"-->
<!--                    :date-time-format="getCalendarDateTimeFormat()">-->
<!--                </mu-date-input>-->
<!--                <mu-date-input-->
<!--                    :label="$t('eventsFilter.eventStartTo')"-->
<!--                    v-model="start_to"-->
<!--                    type="dateTime"-->
<!--                    :error-text="$t(errors.start_to)"-->
<!--                    label-float-->
<!--                    clock-type="24hr"-->
<!--                    view-type="list"-->
<!--                    :date-time-format="getCalendarDateTimeFormat()"-->
<!--                ></mu-date-input>-->

                <mu-date-input
                  :label="$t('eventsFilter.from')"
                  v-model="end_from"
                  type="dateTime"
                  :error-text="$t(errors.end_from)"
                  label-float
                  clock-type="24hr"
                  view-type="list"
                  :first-day-of-week="firstDayOfWeek()"
                  :date-time-format="getCalendarDateTimeFormat()"
                ></mu-date-input>
              </div>
              <div class="date-end">
<!--                <mu-date-input-->
<!--                    :label="$t('eventsFilter.eventEndFrom')"-->
<!--                    v-model="end_from"-->
<!--                    type="dateTime"-->
<!--                    :error-text="$t(errors.end_from)"-->
<!--                    label-float-->
<!--                    clock-type="24hr"-->
<!--                    view-type="list"-->
<!--                    :first-day-of-week="firstDayOfWeek()"-->
<!--                    :date-time-format="getCalendarDateTimeFormat()"-->
<!--                ></mu-date-input>-->
<!--                <mu-date-input-->
<!--                    :label="$t('eventsFilter.eventEndTo')"-->
<!--                    v-model="end_to"-->
<!--                    type="dateTime"-->
<!--                    :error-text="$t(errors.end_to)"-->
<!--                    label-float-->
<!--                    clock-type="24hr"-->
<!--                    view-type="list"-->
<!--                    :first-day-of-week="firstDayOfWeek()"-->
<!--                    :date-time-format="getCalendarDateTimeFormat()"-->
<!--                ></mu-date-input>-->
                <mu-date-input
                  :label="$t('eventsFilter.to')"
                  v-model="start_to"
                  type="dateTime"
                  :error-text="$t(errors.start_to)"
                  label-float
                  clock-type="24hr"
                  view-type="list"
                  :date-time-format="getCalendarDateTimeFormat()"
                ></mu-date-input>
              </div>
            </div>
          </mu-paper>
          <mu-paper class="mu-transition-box location-type-box full-width" :z-depth="0">
            <mu-select
                       :label="$t('events.page.country')"
                       filterable
                       class="narrower"
                       v-model="formData.country_id"
                       :error-text="$t(errors.country_id)"
                       :no-data-text="$t('events.create.step1.noValue')"
            >
              <mu-option
                         :label="$t('nothingSelected')"
                         :value="null"
              ></mu-option>
              <mu-option v-for="(country,index) in this.getCountryList" :key="index"
                         :label="country.name"
                         :value="country.id"
              ></mu-option>
            </mu-select>
            <mu-select
                label-float
                :label="$t('events.page.city')"
                filterable
                class="narrower city-select"
                v-model="formData.city_id"
                :error-text="$t(errors.city_id)"
                :no-data-text="$t('events.create.step1.noValue')"
                :disabled="!formData.country_id">
              <mu-circular-progress v-if="loading" class="progress"></mu-circular-progress>
              <mu-option v-else v-for="(city, index) in cityList"
                         :key="index"
                         :label="city.name"
                         :value="city.id"
              ></mu-option>
            </mu-select>
            <!--            <mu-select-->
            <!--                label-float-->
            <!--                :label="$t('events.create.step1.chooseCategory')"-->
            <!--                filterable-->
            <!--                multiple-->
            <!--                v-model="formData.categories"-->
            <!--                :error-text="$t(errors.categories)"-->
            <!--                :no-data-text="$t('events.create.step1.noValue')"-->
            <!--            >-->
            <!--              <mu-option-->
            <!--                  v-for="(category, index) in categoryList"-->
            <!--                  :key="index"-->
            <!--                  :label="category.name"-->
            <!--                  :value="category.id"-->
            <!--              ></mu-option>-->
            <!--            </mu-select>-->
            <mu-select
                :label="$t('events.create.step1.chooseEventType')"
                class="event-type-select"
                multiple
                v-model="formData.event_types"
                :error-text="$t(errors.event_type_id)"
                label-float
                :no-data-text="$t('events.create.step1.noValue')"
            >
              <mu-option
                  v-for="(type, index) in typeList"
                  :key="index"
                  :label="type.name"
                  :value="type.id"
              ></mu-option>
            </mu-select>
          </mu-paper>

          <div style="text-align: right;">
            <span
                class="cur-p btn-confirm"
                @click="emitEventsFiltered"
            >{{$t('button.confirm')}}</span>
          </div>

        </div>
      </div>
    </mu-expand-transition>
  </mu-flex>
</template>

<script>
import { mapState } from 'vuex';
import DateTimeHelper from '@/_helpers/date-time.helper';
import DatepickerHelper from '@/_helpers/datepicker.helper';

export default {
  name: 'events_filter',
  components: {},
  computed: {
    ...mapState('eventStore', [/* "categoryList", */ 'typeList', 'countryList', 'cityList']),
    ...mapState('locationStore', ['countryList', 'cityList', 'loading']),
    getCountryList() {
        return this.countryList
    }
  },
  watch: {
    start_from: {
      handler() {
        if (this.validateStartFrom()) {
          this.$emit('eventsFilterData', this.formData);
        }
      }
    },
    start_to: {
      handler() {
        if (this.validateStartTo()) {
          this.$emit('eventsFilterData', this.formData);
        }
      }
    },
    end_from: {
      handler() {
        if (this.validateEndFrom()) {
          this.$emit('eventsFilterData', this.formData);
        }
      }
    },
    end_to: {
      handler() {
        if (this.validateEndTo()) {
          this.$emit('eventsFilterData', this.formData);
        }
      }
    },
    'formData.country_id': {
      handler() {
        if (this.validateCountry()) {
          this.cityRequest();
          this.isEmptyCountry = false;
          this.$emit('eventsFilterData', this.formData);
          return;
        }
        this.formData.city_id = null
        this.isEmptyCountry = true;

      }
    },
    'formData.city_id': {
      handler() {
        if (this.validateCity()) {
          this.$emit('eventsFilterData', this.formData);
        }

        if(!this.formData.country_id) {
          this.$emit('eventsFilterData', this.formData);
        }
      }
    },
    /* "formData.categories": {
        deep: true,
        handler() {
          if (this.validateEventCategory()) {
            this.$emit('eventsFilterData', this.formData);
          }
        }
      }, */
    'formData.event_types': {
      handler() {
        this.$emit('eventsFilterData', this.formData);
      }
    },
    'formData.text': {
      handler() {
        this.$emit('eventsFilterData', this.formData);
      }
    },
    dateRange: {
      handler() {
        this.rangeDates()
      }
    },
    toDefault: {
      handler() {
        this.showDateRange = false;
        this.start_from = null;
        this.start_to = null;
        this.end_from = null;
        this.end_to = null;
        this.formData = {};
        this.dateRange = 1;
      }
    }
  },
  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    toDefault: {
      type: String,
    }
  },
  data: () => ({
    isEmptyCountry: true,
    filter: [],
    date_start: '',
    date_end: '',
    showDateRange: false,
    errors: {
      country_id: null,
      city_id: null,
      start_from: '',
      start_to: '',
      end_from: '',
      end_to: '',
      categories: null,
      event_types: null
    },
    formData: {
      country_id: null,
      city_id: null,
      start_from: null,
      start_to: null,
      end_from: null,
      end_to: null,
      categories: null,
      event_types: [],
      text: '',
    },
    start_from: null,
    start_to: null,
    end_from: null,
    end_to: null,
    dateRange: 1,
  }),
  created() {
    this.$store.dispatch('eventStore/eventType');
    // this.$store.dispatch('eventStore/eventCategory');
    this.$store.dispatch('locationStore/countryList');
    this.setSearchKeyword();
  },
  methods: {
    setSearchKeyword() {
      if( this.$route.query && this.$route.query.kw && this.$route.query.kw.trim() !== '') {
        this.formData.text = this.$route.query.kw.trim();
        this.$emit('eventsFilterData', this.formData);
        this.emitEventsFiltered();
      }
    },
    emitEventsFiltered() {
      this.$emit('eventsFiltered');
    },
    validateStartFrom() {
      // let start_from;
      if (DateTimeHelper.isJSDate(this.start_from)) {
        // start_from = new Date(this.start_from).getTime() / 1000;
        this.formData.start_from = this.$moment(this.start_from).format('YYYY-MM-DDTHH:mm');
      } else {
        this.formData.start_from = '';
      }


      // if (!start_from) {
      // 	this.setError({message_key: "errors.validation.date_must_selected"}, "start_from");
      // } else {
      // 	this.rmErr("start_from");
      return true;
      // }
    },
    validateStartTo() {
      // let start_to;

      if (DateTimeHelper.isJSDate(this.start_to)) {
        // start_to = new Date(this.start_to).getTime() / 1000;
        this.formData.start_to = this.$moment(this.start_to).format('YYYY-MM-DDTHH:mm');
      } else {
        this.formData.start_to = '';
      }

/*      if (this.formData.start_to < this.formData.start_from) {
        this.setError({message_key: 'errors.validation.date_should_not_in_past'}, 'start_to');
      }
      // else if (!start_to) {
      // 	this.setError({message_key: "errors.validation.date_must_selected"}, "start_to");
      // }
      else {
        this.rmErr('start_to');
        return true;
      }*/

      return true;
    },

    validateEndFrom() {
      let start_to;
      let end_from;
      if (DateTimeHelper.isJSDate(this.end_from)) {
        start_to = new Date(this.start_to).getTime() / 1000;
        end_from = new Date(this.end_from).getTime() / 1000;
        this.formData.end_from = this.$moment(this.end_from).format('YYYY-MM-DDTHH:mm');
      } else {
        this.formData.end_from = '';
      }


     /* if (+end_from <= +start_to + 60 * 60) {
        this.setError({message_key: "errors.validation.data_end_start_less_1hour"}, "end_from");
      } else if (end_from < start_to) {
        this.setError({message_key: "errors.validation.data_end_start_less_1hour"}, "end_from");
      }
      else if (!end_from) {
      	this.setError({message_key: "errors.validation.date_must_selected"}, "end_from");
      }
      else {
        this.rmErr('end_from');
        return true;
      }*/
      return true;
    },
    validateEndTo() {
      let end_from;
      let end_to;

      if (DateTimeHelper.isJSDate(this.end_to)) {
        end_from = new Date(this.end_from).getTime() / 1000;
        end_to = new Date(this.end_to).getTime() / 1000;
        this.formData.end_to = this.$moment(this.end_to).format('YYYY-MM-DDTHH:mm');
      } else {
        this.formData.end_to = '';
      }
/*
      if (+end_to < +end_from) {
        this.setError({message_key: "errors.validation.data_end_start_less_1hour"}, "end_to");
      } else if (this.formData.end_to < this.formData.end_from) {
        this.setError({message_key: "errors.validation.data_end_start_less_1hour"}, "end_to");
      }
      else if (!end_to) {
      	this.setError({message_key: "errors.validation.date_must_selected"}, "end_to");
      }
      else {
        this.rmErr("end_to");
        return true;
      }*/
      return true;
    },

    cityRequest() {
      this.$store.dispatch('locationStore/cityList', this.formData.country_id)
    },
    validateEventLocation() {
      if (!this.formData.address) {
        // this.setError({message_key: "errors.validation.field_is_required"}, "address");
      } else {
        this.rmErr('address');
        return true;
      }
    },
    validateCountry() {
      if (!this.formData.country_id) {
        // this.setError({message_key: "errors.validation.field_is_required"}, "country_id");
        return true; // TODO: remove validations and olds code
      } else {
        this.rmErr('country_id');
        return true;
      }
    },
    validateCity() {
      if (!this.formData.city_id) {
        // this.setError({message_key: "errors.validation.field_is_required"}, "city_id");
      } else {
        this.rmErr('city_id');
        return true;
      }
    },

    /* validateEventCategory() {
        if (!this.formData.categories) {
          // this.setError({message_key: "errors.validation.field_is_required"}, "eventCategory");
        } else {
          this.rmErr("eventCategory");
          return true;
        }
      }, */

    rangeDates() {
      switch (this.dateRange) {
        case 1:
          this.start_from = null;
          this.start_to = null;
          this.end_from = null;
          this.end_to = null;
          break;
        case 2:
          this.end_from = this.$moment().toDate();
          this.start_to = this.$moment().toDate();
          break;
        case 3:
          this.end_from = this.$moment().subtract(-1, 'days').startOf('day').toDate();
          this.start_to = this.$moment().subtract(-1, 'days').endOf('day').toDate();
          break;
        case 4:
          this.end_from = this.$moment().weekday(1).startOf('day').toDate();
          this.start_to = this.$moment().weekday(5).endOf('day').toDate();
          break;
        case 5:
          this.end_from = this.$moment().weekday(6).startOf('day').toDate();
          this.start_to = this.$moment().weekday(7).endOf('day').toDate();
          break;
        default:
          break;
      }
    },
    setError(err, name) {
      this.errors[name] = err.message_key;
    },
    rmErr(name) {
      this.errors[name] = null;
    },

    getCalendarDateTimeFormat() {
      return DatepickerHelper.getMuseUIDatepickerDateTimeFormat();
    },

    firstDayOfWeek() {
      return DatepickerHelper.getFirstDayOfWeekNumber();
    },
  }
}
</script>

<style scoped lang="scss">

  .btn-confirm {
    display: inline-block;
    border-radius: 99px;
    background-color: $ew-blue;
    color: #fff;
    font-size: 1.8rem;
    padding: 0.8rem 5.5rem 0.4rem;
    line-height: 2.2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    &:hover {
      opacity: 0.8;
    }
  }

  .filter-box {
    margin-top: 2rem;
    border-radius: 25px;
    box-shadow: 30px 30px 80px rgba(55, 84, 170, 0.1);
    background-color: #fff;
    margin-bottom: 100px;
    padding: 40px 20px;

    .content {
      width: auto;
      margin: auto;
      max-width: 905px;
    }

    .mu-input {
      width: auto;
      margin: 0 35px 0 0;
      font-size: 1.4rem;
    }

    * {
      font-weight: 300;
    }


  }

  .mu-transition-row {
    margin-bottom: 16px;
    flex-flow: row wrap;
  }

  .date-start {
    display: flex;
    justify-content: space-between;
  }

  .date-end {
    display: flex;
    justify-content: space-between;
  }

  .full-width {
    width: 100%;
  }

  .more-dates {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin: 0 0 18px;

    .control {
      padding-right: 40px;
    }

    * {
      font-weight: 500;
    }

  }

  .span-btn {
    padding: 8px;
    margin-left: auto;
    color: #3aa1ff;
    cursor: pointer;
    justify-self: flex-end;
    align-self: flex-end;
  }

  .search-box {
    margin-bottom: 28px;

    * {
      font-weight: 500;
    }
  }

  .mu-transition-box {
    background-color: transparent;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-items: flex-start;
  }

</style>

<style lang="scss">

  .filter-box .mu-radio-icon {
    display: none !important;
  }

  .filter-box .mu-radio-label {
    font-size: 1.4rem;
  }

  .filter-box .mu-radio-checked .mu-radio-label {
    margin-bottom: -1px;
    color: $ew-blue !important;
    border-color: $ew-blue !important;
    border-style: solid;
    border-width: 0 0 1px;
  }

  .location-type-box {
    margin-bottom: 40px;

    .mu-input {
      max-width: 240px;

      &.narrower {
        max-width: 350px;
      }

    }

    .mu-input-label,
    .mu-select-content {
      white-space: nowrap !important;
      overflow: hidden !important;
      max-width: 85%;
    }

    .city-select.disabled {

      opacity: 0.5;

      .mu-input-line.disabled {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: #ddd;
        height: 100%; /* height needed for disabled-icon hover cursor */
      }
    }

  }

  /* TODO: redesign.
  This makes it slightly less bad-looking, but it is still bad.
  Moves the filterable input (keyboard-filter options) over the selected options.
  We need to redesign how multiple current selected options are displayed
  */
  .filter-box .event-type-select {
    /* if we make .mu-select-input absolutely positioned, the field has no width. */
    min-width: 200px;

    /* Separates the words visually */
    .mu-selection-text {
      margin-right: 0.5em;
      font-size: 1.2rem;
    }

    /* makes the input absolutely positioned, so that the whole field looks less BAD. */
    .mu-select-input {
      position: absolute !important;
      top: 0 !important;
      height: 100% !important;
      margin: 0;
    }

  }

</style>
